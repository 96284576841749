<template>
  <div class="shipOwnerInfo">
    <div class="body">
      <div class="title">证照信息</div>
      <div class="content">
        <div class="upBox">
          <el-image
            :src="shipOwnerInfo.IDCardFrontUrl"
            :preview-src-list="srcList"
            class="img"
          >
          </el-image>
          <div class="upText">身份证(正面)</div>
        </div>
        <div class="upBox">
          <el-image
            :src="shipOwnerInfo.IDCardBackUrl"
            :preview-src-list="srcList"
            class="img"
          >
          </el-image>
          <div class="upText">身份证(反面)</div>
        </div>
        <div class="upBox">
          <el-image
            :src="shipOwnerInfo.SeamanPassportUrl"
            :preview-src-list="srcList"
            class="img"
          >
          </el-image>
          <div class="upText">船员证</div>
        </div>
        <div class="upBox">
          <el-image
            :src="shipOwnerInfo.FlightManagementBookUrl"
            :preview-src-list="srcList"
            class="img"
          >
          </el-image>
          <div class="upText">船员船务薄</div>
        </div>
      </div>

      <div class="title">基本资料</div>
      <el-form
        class="shipOwnerInfo"
        :model="shipOwnerInfo"
        ref="shipOwnerInfo"
        disabled
      >
        <el-form-item label="船东姓名" prop="ShipownerName">
          <el-input
            v-model="shipOwnerInfo.ShipownerName"
            placeholder="输入船东姓名"
            maxlength="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="船东身份证号" prop="ShipownerIDCard">
          <el-input
            v-model="shipOwnerInfo.ShipownerIDCard"
            placeholder="输入船东身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="船东手机号" prop="TelPhone">
          <el-input
            v-model="shipOwnerInfo.TelPhone"
            placeholder="输入船东手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="BankName">
          <el-input
            v-model="shipOwnerInfo.BankName"
            placeholder="输入开户银行"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="BankCardNumber">
          <el-input
            v-model="shipOwnerInfo.BankCardNumber"
            placeholder="输入银行账号"
            maxlength="32"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: [],
      objKeyList: [
        "IDCardFrontUrl",
        "IDCardBackUrl",
        "SeamanPassportUrl",
        "FlightManagementBookUrl",
      ],
    };
  },
  props: {
    // 船东信息
    shipOwnerInfo: {
      type: Object,
      default: true,
    },
  },

  mounted() {
    this.objKeyList.forEach((it) => {
      this.srcList.push(this.shipOwnerInfo[it] || undefined);
    });
  },
};
</script>

<style lang="scss" scoped>
.shipOwnerInfo {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  .title {
    color: #2081ff;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    margin-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: #2081ff;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0 0 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: #7e7e7e;
    }
  }

  .formList {
    display: flex;
    flex-wrap: wrap;
  }

  .img {
    width: 278px;
    height: 174px;
    border-radius: 10px;
  }

  .shipOwnerInfo {
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep .el-form-item__content {
    margin-right: 20px !important;

    .el-input__inner,
    .el-date-editor {
      width: 200px;
    }
  }
}
</style>
