<template>
  <div class="shipInfo">
    <div class="body">
      <el-form class="shipForm" ref="shipForm" disabled>
        <div class="title">证件信息</div>
        <div class="content">
          <div class="upBox">
            <el-image
              :src="shipInfo.RiverLicenseURL"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">船舶内河适航证</div>
          </div>
          <div class="upBox">
            <el-image
              :src="shipInfo.ShipLicenseFrontURL"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">船舶营运证正面</div>
          </div>
          <div class="upBox">
            <el-image
              :src="shipInfo.ShipLicenseBackURL"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">船舶营运证反面</div>
          </div>
        </div>
        <div class="content" style="margin-top: 5px">
          <div class="upBox">
            <el-image
              :src="shipInfo.ShipPhotoURL"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">船舶图片</div>
          </div>
          <div class="upBox" v-if="shipInfo.AISURL">
            <el-image
              :src="shipInfo.AISURL"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">AIS证书</div>
          </div>
          <div class="upBox" v-if="shipInfo.RadioLicenseURL">
            <el-image
              :src="shipInfo.RadioLicenseURL"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">电台执照</div>
          </div>
        </div>
        <div class="title">基本信息</div>
        <div class="content" style="justify-content: left">
          <el-form-item label="船舶名称" prop="ShipName">
            <el-input
              v-model="shipInfo.ShipName"
              placeholder="请输入船舶名称"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="船舶登记号" prop="ShipRegNumber">
            <el-input
              v-model="shipInfo.ShipRegNumber"
              placeholder="请输入船舶登记号"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="船舶类型" prop="ShipType">
            <el-input
              v-model="shipInfo.ShipType"
              placeholder="请输入船舶类型"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="船舶编号" prop="ShipNO">
            <el-input
              v-model="shipInfo.ShipNO"
              placeholder="请输入船舶编号"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="船籍港口" prop="ShipPort">
            <el-input
              v-model="shipInfo.ShipPort"
              placeholder="请输入船籍港口"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="船检登记号" prop="ShipCheckNumber">
            <el-input
              v-model="shipInfo.ShipCheckNumber"
              placeholder="请输入船检登记号"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="船舶所有人" prop="Shipowner">
            <el-input
              v-model="shipInfo.Shipowner"
              placeholder="请输入船舶所有人"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="船舶经营人" prop="ShipOperator">
            <el-input
              v-model="shipInfo.ShipOperator"
              placeholder="请输入船舶经营人"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="使用期限" prop="UsagePeriodDate">
            <el-date-picker
              v-model="shipInfo.UsagePeriodDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择使用期限"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="发证机关" prop="IssuingAuthority">
            <el-input
              v-model="shipInfo.IssuingAuthority"
              placeholder="请输入发证机关"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="发证日期" prop="IssuingDate">
            <el-date-picker
              v-model="shipInfo.IssuingDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择发证日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="船舶材料" prop="ShipBodyMaterial">
            <el-input
              v-model="shipInfo.ShipBodyMaterial"
              placeholder="请输入船舶材料"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="船舶总吨" prop="ShipTonnage">
            <el-input
              v-model="shipInfo.ShipTonnage"
              placeholder="请输入船舶总吨"
              onkeyup="value=value.replace(/[^\d.]/g,'').replace(/(\d)(\.)(\d*)(\2*)(\d*)/,'$1$2$3$5').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
              maxlength="9"
            ></el-input>
          </el-form-item>
          <el-form-item label="建成日期" prop="ShipCreateDate">
            <el-date-picker
              v-model="shipInfo.ShipCreateDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择建成日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="改建日期" prop="ShipRenovationDate">
            <el-date-picker
              v-model="shipInfo.ShipRenovationDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择改建日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="主机功率" prop="TotalPower">
            <el-input
              v-model="shipInfo.TotalPower"
              placeholder="请输入主机功率"
              onkeyup="value=value.replace(/[^\d.]/g,'').replace(/(\d)(\.)(\d*)(\2*)(\d*)/,'$1$2$3$5').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
              maxlength="9"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: [],
      objKeyList: [
        "RiverLicenseURL",
        "ShipLicenseFrontURL",
        "ShipLicenseBackURL",
        "ShipPhotoURL",
        "AISURL",
        "RadioLicenseURL",
      ],
    };
  },
  props: {
    shipInfo: {
      type: Object,
      default: true,
    },
  },

  mounted() {
    this.objKeyList.forEach((it) => {
      this.srcList.push(this.shipInfo[it] || undefined);
    });
  },
};
</script>

<style lang="scss" scoped>
.shipInfo {
  ::v-deep .el-dialog {
    margin-top: 6vh !important;
  }

  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  .shipForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .title {
      color: #2081ff;
      position: relative;
      text-indent: 15px;
      font-size: 14px;
      margin-bottom: 10px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: #2081ff;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .upBox {
        text-align: center;

        .upText {
          font-size: 14px;
          font-weight: bold;
          margin: 10px 0 0 0;
          color: #333333;
        }
      }

      .el-upload__tip {
        color: #7e7e7e;
      }
    }

    .img {
      width: 278px;
      height: 174px;
      border-radius: 10px;
    }
  }

  ::v-deep .el-form-item__content {
    margin-right: 20px !important;

    .el-input__inner,
    .el-date-editor {
      width: 200px;
    }
  }
}
</style>
